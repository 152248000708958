<template>
  <div class="title">
    <span>页面不存在</span>
  </div>
</template>

<script>
export default {
  name: "error"
}
</script>

<style scoped lang="scss">
.title {
  margin: 0 auto;
  text-align: center;
}
</style>